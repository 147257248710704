<template>
  <v-app id="inspire">
    <v-main
      :style="light ? 'background: #f1f1f1 ;' : ''"
      :class="{ 'pattern-bg-dark': dark }"
    >
      <v-container class="fill-height justify-center align-center" fluid>
        <v-row class="fill-height" align="center" justify="center">
          <v-col class="fill-height d-flex justify-center align-center">
            <v-card
              class="pa-6 pa-md-12 bg-circle"
              width="500px"
              :height="$vuetify.breakpoint.mdAndUp ? '500px' : ''"
              style="z-index: 2 !important; "
              :style="$vuetify.breakpoint.mdAndUp ? 'border-radius: 50%;' : ''"
            >
              <v-card-title
                class="pt-0 d-flex flex-column align-center justify-center"
                style="width: 100%"
              >
                <div class="display-2 font-weight-bold white--text">
                  TOCS
                </div>
                <div class="body-2 font-weight-bold primary--text">
                  task - organizer - control - scheduler
                </div>
              </v-card-title>
              <v-card-text class="pa-0 px-md-8 py-md-2">
                <v-form v-model="valid" ref="form">
                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-inner-icon="person"
                    solo
                    flat
                    full-width
                    outlined
                    type="email"
                    v-model="login.email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>

                  <v-text-field
                    :append-icon="show ? 'visibility' : 'visibility_off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    name="senha"
                    label="Senha"
                    prepend-inner-icon="lock"
                    full-width
                    solo
                    flat
                    outlined
                    v-model="login.senha"
                    class="input-group--focused mt-1"
                    @click:append="show = !show"
                    @keyup.enter="logar"
                  ></v-text-field>

                  <div>
                    <v-checkbox class="ma-0" v-model="checkbox">
                      <template v-slot:label>
                        <div class="body-2 font-weight-bold primary--text">
                          Lembrar Email
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </v-form>
              </v-card-text>

              <template v-if="erros.length > 0">
                <v-card-text class="pa-0 mb-4 red--text">
                  <div class="py-1" v-for="(erro, index) in erros" :key="index">
                    <v-icon class="mr-2 red--text">error</v-icon>
                    <span>{{ erro }}</span>
                  </div>
                </v-card-text>
              </template>

              <v-card-actions class="d-flex justify-center">
                <v-btn
                  class="elevation-0 mt-1 px-8 primary"
                  style=" z-index: 2 !important;"
                  @click.prevent="logar"
                  x-large
                  max-height="50px"
                  dark
                  :disabled="!valid"
                  :loading="loading"
                >
                  Entrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="bg-circle-animation d-none d-md-block">
        <div class="circle" style="animation-delay: 0s;"></div>
        <div class="circle" style="animation-delay: 1.5s;"></div>
        <div class="circle" style="animation-delay: 3s;"></div>
        <div class="circle" style="animation-delay: 4.5s;"></div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { getUserClassificacoes } from "@/services/api/usuarios.api.js";
import { mapState } from "vuex";

export default {
  name: "Login",
  data: () => ({
    show: false,
    valid: false,
    loading: false,
    checkbox: false,
    userclassificacoes: [],
    userTecnico: false,
    login: {
      email: "",
      senha: "",
    },
    erros: [],
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => v.length >= 3 || "Minimo 4 caracteres",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "O e-mail precisa ser valido!";
      },
    },
  }),
  computed: {
    ...mapState({
      dark: (state) => state.template.dark,
      light: (state) => state.template.light,
    }),
    ...mapState("Usuario", {
      auto_redirect: (state) => state.auto_redirect,
    }),
  },
  methods: {
    M_getUserClassificacoes(id) {
      return new Promise((resolve, reject) => {
        getUserClassificacoes(id)
          .then((response) => {
            this.userclassificacoes = response;
            let tecnico = this.userclassificacoes.find((item) => item.id === 2);
            resolve(tecnico);
          })
          .catch((error) => reject(error));
      });
    },
    logar() {
      let locale = "";
      if (localStorage.getItem("locale")) {
        locale = localStorage.getItem("locale");
      } else {
        locale = "es";
      }
      this.loading = true;
      this.erros = [];
      this.$Progress.start();

      this.$store
        .dispatch("Usuario/logarUsuario", this.login)
        .then(() => {
          if (window.localStorage.email) {
            if (!this.checkbox) {
              window.localStorage.removeItem("email");
            }
          } else if (this.checkbox) {
            window.localStorage.email = this.login.email;
          }
          this.$store.dispatch("Usuario/getUsuario").then(() => {
            this.$store.dispatch("Componentes/getComponentes").then(() => {
              import(
                /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
              )
                .then((msgs) => {
                  this.$root.$i18n.setLocaleMessage(locale, msgs.default);
                  this.$root.$i18n.locale = locale;
                  this.loading = false;
                  this.$Progress.finish();
                  this.$store.dispatch("Tocsconfig/getModulos");
                  if (this.auto_redirect && window.localStorage.last_route) {
                    this.$router.push({
                      path: window.localStorage.last_route,
                    });
                  } else {
                    this.$router.push({ name: "Home" });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$Progress.fail();
                  this.loading = false;
                });
            });
          });
          this.$store.dispatch("Usuario/getUsuarios");
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.fail();
          if (error) this.erros.push(error);
        });
    },
  },
  mounted() {
    if (window.localStorage.email) {
      this.login.email = window.localStorage.email;
      this.checkbox = true;
    }

    if (process.env.VUE_APP_SENHA_MICHEL) {
      this.login.senha = process.env.VUE_APP_SENHA_MICHEL;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg-circle {
  background: rgb(244, 183, 47);
  background: radial-gradient(
    circle,
    rgba(244, 183, 47, 1) 0%,
    rgba(242, 155, 45, 1) 50%,
    rgba(240, 134, 48, 1) 100%
  );
}

.bg-circle-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.bg-circle-animation .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 5px rgba(240, 134, 48, 1);
  box-shadow: 0 0 0 5px rgba(240, 134, 48, 1);
  opacity: 0;
  -webkit-animation: circleScaleIn 8s linear infinite;
  animation: circleScaleIn 8s linear infinite;
}

@-webkit-keyframes circleScaleIn {
  0% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }

  25% {
    width: 400px;
    height: 400px;
    opacity: 0.35;
  }

  50% {
    width: 600px;
    height: 600px;
    opacity: 0.75;
  }

  to {
    width: 1200px;
    height: 1200px;
    opacity: 0;
  }
}

@keyframes circleScaleIn {
  0% {
    width: 400px;
    height: 400px;
    opacity: 0;
  }

  25% {
    width: 600px;
    height: 600px;
    opacity: 0.75;
  }

  to {
    width: 1200px;
    height: 1200px;
    opacity: 0;
  }
}
</style>
